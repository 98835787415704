"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";
import "../libs/ajaxInclude";

function ajaxIncludeCallback() {
    let includedContainer = $(this).find('div').eq(0);

    if ($(this).is('[data-target]')) {
        let targetContainer = $($(this).data('target'));
        if (targetContainer.length > 0) {
            includedContainer = targetContainer.next();
        }
    }

    //remove style attribute with min-height
    $(this).removeAttr('style');

    includedContainer.each(function () {
        let $this = $(this);
        initModulesInScope($this);

        let wishlist = false;
        let wishListButtons = $this.find('.js-wishlist-btn');
        wishListButtons.removeClass('is-added');


        //set intervall function to check if wishlist is loaded and loop every 0.25s for 5s
        let interval = setInterval(function () {
            wishlist = window.app.wishlist;
            if (wishlist) {
                clearInterval(interval);
                $.each(wishlist.items, function (index, value) {
                    $this.find('.js-wishlist-btn[data-article-id="' + index + '"]').addClass('is-added');
                });
            }
        }, 250);
    });


    $(this).off('ajaxInclude', ajaxIncludeCallback);

    if (_config && _config['debug']) {
        console.warn('ajax included: ', includedContainer);
    }
}

export function initInScope($scope) {
    let ajaxIncludeElements = $scope.find('.js-ajaxInclude');

    ajaxIncludeElements.on('ajaxInclude', function () {
        if (window.picturefillCFG) {
            picturefill();
        }
    });

    let ajaxIncludeOptions = {
        device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
    };

    ajaxIncludeElements.on('ajaxInclude', ajaxIncludeCallback).extendAjaxInclude(ajaxIncludeOptions);

    ajaxIncludeElements.filter('[data-interaction=click]').on('click', function () {
        $(this).removeAttr('data-interaction');
        $(this).extendAjaxInclude(ajaxIncludeOptions);
    });
}
