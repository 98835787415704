'use strict'
import fetch from '@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill';
import initModulesInScope from "@elements/init-modules-in-scope";
import {initSlider} from "@elements/slider";
import {clearAll, showNotification} from "@elements/alert-notification"; // Edge Polyfill

export function initInScope($scope) {

    let $modalBtn =  $scope.find('.js-ajax-modal-btn'),
        $modalContent;

    const $loading = $('.js-modal__loading');

    let pendingRequest;

    $modalBtn.on('click', (e) => {
        let url = $(e.currentTarget).data('url');
        let $modal =  $($(e.currentTarget).data('target'));
        $modalContent = $modal.find('.js-ajax-modal-content');
        let $modalNotifications = $modal.find('.js-ajax-modal__notifications');

        loadAjaxModal(url, $loading, $modalContent, $modalNotifications, pendingRequest)
    });


}

export function loadAjaxModal(url, $loading, $modalContent, $modalNotifications, pendingRequest) {
    showLoading($loading, $modalContent);
    if(pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    pendingRequest = fetch(url, {
        method: 'GET'
    });

    clearAll({
        $container: $modalNotifications
    });

    showNotification(pendingRequest, {$container: $modalNotifications});

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if(res.success){
            if(res.html){
                const $response = $(res.html);
                if ($modalContent.length) {
                    $modalContent.html($response);
                    initModulesInScope($modalContent);

                    /* set redirect input */
                    let $redirectUrlInput = $modalContent.find(".js-redirect-url");
                    if ($redirectUrlInput.length) {
                        let redirectUrl = window.location.pathname;
                        $redirectUrlInput.val(redirectUrl);
                    }

                    let ajaxSlider = $modalContent.find(".js-recommendation-slider");
                    if(ajaxSlider.length) {
                        let ajaxSliderOptions = {
                            prevArrow:"<button type='button' class='slick-prev' aria-label='prev'><span class='icon icon-arrow-left'></span></button>",
                            nextArrow:"<button type='button' class='slick-next' aria-label='next'><span class='icon icon-arrow-right'></span></button>",
                            infinite: false,
                            dots: true,
                            adaptiveHeight:false,
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        };
                        if (matchMedia('(max-width: 767px)').matches) {
                            ajaxSliderOptions.slidesToShow = 1.2;
                            ajaxSliderOptions.slidesToScroll = 1;
                        } else if (matchMedia('(max-width: 1199px)').matches) {
                            ajaxSliderOptions.slidesToShow = 3;
                            ajaxSliderOptions.slidesToScroll = 3;
                        }

                        initSlider(ajaxSlider, ajaxSliderOptions);
                    }


                    /* find value from newsletter input */
                    let $newsletterInput = $(".js-get-newsletter-value");
                    let $newsletterModalInput = $modalContent.find(".js-set-newsletter-value");

                    if($newsletterInput.length && $newsletterModalInput.length){
                        let newsletterValue = $newsletterInput.val();
                        if(newsletterValue){
                            $newsletterModalInput.val(newsletterValue);
                        }
                    }

                }
            }
        }
    }).finally(() => {
        hideLoading($loading, $modalContent);
    })
}

function showLoading($loading, $modalContent){
    $loading.removeAttr('hidden');
    $modalContent.attr('hidden', true);
    $modalContent.addClass('modal-is-loading');
}
function hideLoading($loading, $modalContent){
    $loading.attr('hidden', true);
    $modalContent.attr('hidden', false);
    setTimeout(function(){
        $modalContent.removeClass('modal-is-loading');
    }, 250);
}
