"use strict";
import {showNotification} from "@elements/alert-notification";
import initModulesInScope from "@elements/init-modules-in-scope";
import {responseTracking} from "@elements/tracking";

/*
*  check also some adaptions in lazyData.js
*/


function setWishlistCount(count) {
    let counter = $('.js-wishlist-count');
    if(count <= 0){
        counter.empty().attr('hidden', true);
    }else {
        counter.text(count).removeAttr('hidden');
    }
}

function wishlistAction(articleId, productId, size, action, button) {
    let url = _config.wishlistEditUrl;
    let $loading = $('.js-wishlist__loading');
    let pendingRequest;
    let $infoBox = $('.js-wishlist-info');
    let isWishlistList = _config.isWishlistList;
    if (action === 'remove') {url = _config.wishlistRemoveUrl;}
    if (action === 'add') {url = _config.wishlistAddUrl;}

    if (pendingRequest) {
        pendingRequest.abort();
    }

    if(action === 'edit' && $loading.length ) {
        $loading.removeAttr('hidden');
    }

    pendingRequest = $.ajax(url, {
        data: 'size=' + size + '&articleId=' + articleId + '&productId=' + productId,
        dataType: 'json',
        headers: {
            'ajax' : true,
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
        }
    }).done(function (data) {
        if(data.success === true) {
            setWishlistCount(data.count);
            if(articleId) {
                button = $('.js-wishlist-btn[data-article-id="' + articleId + '"]');
                if(action === 'add') {
                    $(button).addClass('is-added');
                } else if (action === 'remove') {
                    $(button).removeClass('is-added');
                } else {
                    $loading.attr('hidden', true);
                }
            }
            if(productId) {
                button = $('.js-wishlist-btn[data-product-id="' + productId + '"]');
                if(action === 'add') {
                    $(button).addClass('is-added');
                } else if (action === 'remove') {
                    $(button).removeClass('is-added');
                } else {
                    $loading.attr('hidden', true);
                }
            }
        }

        if(data.articleId || data.productId) {
            //find js-wishlist-btn with data-article-id and remove closest .js-wishlist-item
            let currentBtn = false;

            if(data.articleId) {
                currentBtn = $('.js-wishlist-btn[data-article-id="' + data.articleId + '"]');
            }
            if(data.productId) {
                currentBtn = $('.js-wishlist-btn[data-product-id="' + data.productId + '"]');
            }

            currentBtn.closest('.js-wishlist-item-holder').remove();

            if ($infoBox.length) {
                $infoBox.remove();
            }

            let count = $('.js-wishlist-item').length;
            if((count === 0) && isWishlistList){
                location.reload();
            }
        }

        showNotification(pendingRequest);
        responseTracking(pendingRequest);

        pendingRequest = null;
    }).fail(function(a){
        console.warn(a);
    });
}

export function initInScope ($scope) {
    let $button = $scope.find('.js-wishlist-btn');
    let data = false;

    if(window.app.wishlist) {
        data = window.app.wishlist;
        $.each(data.items, function (index, value) {
            $scope.find('.js-wishlist-btn[data-article-id="' + value + '"]').addClass('is-added');
        });
    }

    $button.on('click', (e) => {
        let productId = false;
        let size = false;

        let articleId = $(e.currentTarget).attr('data-article-id');
        if ($(e.currentTarget).attr('data-product-id')) {
            productId = $(e.currentTarget).attr('data-product-id');
        }

        let url = new URL(window.location.href);

        //get size in url parameter on grid
        if (url.searchParams.get("size[]")) {
            size = url.searchParams.get("size[]");
        }

        if ($(e.currentTarget).attr('data-size')) {
            size = $(e.currentTarget).attr('data-size');
        }

        if($(e.currentTarget).hasClass('is-added')) {
            wishlistAction(articleId, productId, size, 'remove', e.currentTarget);
        } else if($(e.currentTarget).hasClass('is-wishlist')) {
            wishlistAction(articleId, productId, size, 'edit', e.currentTarget);
        } else  {
            wishlistAction(articleId, productId, size, 'add', e.currentTarget);
        }
    });


    /* reload item in wishlist list */
    let $inputs = $scope.find('.js-load-wishlist-item');
    $inputs.each(function () {
        let $input = $(this);
        $input.on('change', function () {
            let $form = $(this).closest('form');
            let $wishlistItem = $(this).closest('.js-wishlist-item-holder');
            let data = $form.serialize();
            let url = $form.attr("data-wishlist-item-action");
            let pendingRequest;

            if (pendingRequest) {
                pendingRequest.abort();
            }

            pendingRequest = $.ajax(url, {
                data: data,
                dataType: 'json',
                headers: {
                    'ajax' : true,
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache',
                }
            }).done(function (data) {
                if(data.success === true) {
                    $wishlistItem.html(data.html);
                }

                showNotification(pendingRequest);
                initModulesInScope($wishlistItem);

                pendingRequest = null;
            }).fail(function(a){
                console.warn(a);
            });

        });
    })
}