"use strict";

export function initInScope ($scope) {
    let $inputs = $scope.find('.js-submit-on-trigger');

    $inputs.each(function () {
        let $input = $(this);
        let trigger = $input.data('submit-on-trigger') || 'change';

        $input.on(trigger, function () {
            $(this).closest('form').submit();
        });
    })

}