import $ from 'jquery';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification} from '@elements/alert-notification';
import {responseTracking} from "@elements/tracking";
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from "@elements/fetch"

export function init(){
    let url = _config.lazyDataUrl;
    let backForwardCache = false;

    window.addEventListener('pageshow', function(evt) {
        if (evt.persisted === true || (window.performance && window.performance.navigation.type === 2) ) {
            backForwardCache = true;
            lazyData ();
        }
    });

    lazyData ();

    function lazyData (){
        /* Size Notification Modal */
        let $sizeNotificationLazyData = $('.js-size-notification-lazy-data');
        let $sizeNotificationLazyDataLoading = $('.js-size-notification-lazy-data__loading');

        /* PDS */
        let $pdsLazyData = $('.js-pds-lazy-data');
        let $pdsLazyDataLoading = $('.js-pds-lazy-data__loading');

        /* account */
        let $accountLoading = $('.js-account-list__loading');
        let $accountResult = $('.js-account-list__result');

        /* cart */
        let $initialCartLoading = $('.js-cart__list-loading');
        let $initialCartResult = $('.js-cart__list-result');
        let $initialCartCount = $('.js-num-of-products');

        if (url) {
            if (($accountResult && $accountResult.length) || ($pdsLazyData && $pdsLazyData.length) || ($initialCartResult && $initialCartResult.length) && ($initialCartCount && $initialCartCount.length)) {
                $accountLoading.attr('hidden', null);
                $accountResult.attr('hidden', 'hidden');

                $initialCartLoading.attr('hidden', null);
                $initialCartResult.attr('hidden', 'hidden');

                let request = fetch(url, {
                    method: 'get',
                    headers: {
                        'pragma': 'no-cache',
                        'cache-control': 'no-cache',
                        'content-type': 'application/json',
                    }
                });

                showNotification(request);
                responseTracking(request);

                request.then(response => response.json())
                    .then(result => {
                        if (result.success) {
                            if (result.accountDropIn) {
                                setContent($accountResult, $accountLoading, result.accountDropIn);
                            }
                            if (result.cartListContent) {
                                setContent($initialCartResult, $initialCartLoading, result.cartListContent);
                            }
                            if (result.pdsAjaxInfo) {
                                setContent($pdsLazyData, $pdsLazyDataLoading, result.pdsAjaxInfo);
                            }
                            if (result.sizeNotification) {
                                setContent($sizeNotificationLazyData, $sizeNotificationLazyDataLoading, result.sizeNotification);
                                $('#sizeNotificationSuccess').modal('show');
                            }
                            if(result.wishlist){
                                window.app.wishlist = result.wishlist;
                                let counter = $('.js-wishlist-count');
                                if(result.wishlist.count <= 0){
                                    counter.empty().attr('hidden', true);
                                }else {
                                    counter.text(result.wishlist.count).removeAttr('hidden');
                                }

                                if(backForwardCache) {
                                    let data = window.app.wishlist;
                                    $.each(data.items, function (index, value) {
                                        let $button = $('.js-wishlist-btn[data-article-id="' + index + '"]');
                                        $button.addClass('is-added');
                                    });
                                }
                            }

                            //upselling modal
                            if(result.showUpsellingModal){
                                let $modal = $(`#${result.upsellingModalId}`).first();
                                let $modalContent = $modal.find('.js-ajax-modal-content');
                                if ($modalContent.length) {
                                    $modalContent.html(result.upsellingModalContent);
                                    initModulesInScope($modalContent);
                                }
                                $modal.modal('show');
                            }

                            if (result.cartCount > 0) {
                                $initialCartCount.text(result.cartCount).attr('hidden', false);
                            }
                        }
                    }).catch(e => {
                    console.error(e);
                    $accountLoading.attr('hidden', 'hidden');
                    $accountResult.attr('hidden', false);

                    $initialCartLoading.attr('hidden', 'hidden');
                    $initialCartResult.attr('hidden', false);
                });
            }
        }
    }
}

function setContent(result, loading, content) {
    if (content) {
        result.empty().append(content);
        loading.attr('hidden', 'hidden');
        result.attr('hidden', null);

        initModulesInScope(result);
    } else {
        loading.attr('hidden', 'hidden');
        result.attr('hidden', 'hidden');
    }
}